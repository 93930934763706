/* Container Styling */
.profile-container {
    padding: 30px;
    margin: 40px auto;
    max-width: 700px;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
  
  /* General Form Styling */
  .profile-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  
  .profile-form h1 {
    font-size: 30px;
    color: #333;
    margin-bottom: 25px;
    text-align: left;
    width: 100%;
  }
  
  /* Label and Input Styling */
  .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .profile-form label {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
    color: #555;
  }
  
  .profile-form input {
    width: 100%;
    max-width: 520px;
    padding: 14px 18px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    background-color: #fafafa;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .profile-form input:focus {
    border-color: #4a90e2;
    background-color: #fff;
    outline: none;
  }
  
  /* Button Styling */
  .update-button {
    padding: 12px 25px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background-color: #28a745;
    color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .update-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }
  
  /* Error and Success Message Styling */
  .error, .success {
    width: 100%;
    max-width: 520px;
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    text-align: center;
  }
  
  .error {
    background-color: #ffdddd;
    color: #d32f2f;
  }
  
  .success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .logout-button {
    padding: 12px 25px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background-color: #f44336;
    color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-top: 20px;
  }
  
  .logout-button:hover {
    background-color: #d32f2f;
    transform: translateY(-2px);
  }
  


  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .profile-container {
      padding: 20px;
      margin: 20px;
    }
  
    .profile-form input {
      font-size: 15px;
      padding: 10px 15px;
    }
  
    .update-button {
      font-size: 16px;
      padding: 10px 18px;
    }
  }
  