/* Container Styling */
.add-tenant-container {
  padding: 30px;
  margin: 40px auto;
  max-width: 700px;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

/* General Form Styling */
.add-tenant-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-tenant-form h2 {
  font-size: 30px;
  color: #333;
  margin-bottom: 25px;
}

/* Label and Input Styling */
.form-group {
  display: flex;
  flex-direction: column;
}

.add-tenant-form label {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
  color: #555;
}

.add-tenant-form input,
.add-tenant-form select {
  width: 100%;
  max-width: 520px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fafafa;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
}

.add-tenant-form input:focus,
.add-tenant-form select:focus {
  border-color: #4a90e2;
  background-color: #fff;
  outline: none;
}

/* File Upload */
.upload-label {
  width: 100%;
  max-width: 520px;
  height: 60px;
  border: 2px dashed #bbb;
  border-radius: 8px;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  color: #777;
  cursor: pointer;
  margin-bottom: 15px;
}

.upload-label:hover {
  border-color: #4a90e2;
}

.document-input, .image-input {
  display: none;
}

/* Button Styling */
.submit-button,
.delete-button {
  padding: 12px 25px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button {
  background-color: #28a745;
  color: #fff;
}

.submit-button:hover {
  background-color: #218838;
}

.delete-button {
  background-color: #f44336;
  color: #fff;
}

.delete-button:hover {
  background-color: #d32f2f;
}

/* Pending Rent and Overdue Status */
.pending-rent, .overdue-status {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border-radius: 8px;
  max-width: 520px;
}

.pending-rent {
  color: #333;
  background-color: #ffefc1;
}

.overdue-status {
  color: #d32f2f;
  background-color: #ffdddd;
}

/* Error and Success Message Styling */
.error, .success {
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  max-width: 520px;
}

.error {
  background-color: #ffdddd;
  color: #d32f2f;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .add-tenant-container {
    padding: 20px;
  }

  .add-tenant-form input,
  .add-tenant-form select {
    font-size: 15px;
    padding: 10px;
  }

  .submit-button,
  .delete-button {
    font-size: 16px;
    padding: 10px 18px;
  }
}
