/* Container Styling */
.expense-form-container {
  padding: 30px;
  margin: 40px auto;
  max-width: 700px;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

/* General Form Styling */
.expense-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.expense-form h2 {
  font-size: 30px;
  color: #333;
  margin-bottom: 25px;
  text-align: left;
  width: 100%;
}

/* Label and Input Styling */
.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.expense-form label {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
  color: #555;
}

.expense-form input,
.expense-form select,
.expense-form textarea {
  width: 100%;
  max-width: 520px;
  padding: 14px 18px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fafafa;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.expense-form input:focus,
.expense-form select:focus,
.expense-form textarea:focus {
  border-color: #4a90e2;
  background-color: #fff;
  outline: none;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

/* Document Upload Styling */
.upload-label {
  width: 100%;
  max-width: 520px;
  height: 60px;
  border: 2px dashed #bbb;
  border-radius: 8px;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  color: #777;
  cursor: pointer;
  margin-bottom: 15px;
  transition: border-color 0.3s ease;
}

.upload-label:hover {
  border-color: #4a90e2;
}

.document-input {
  display: none;
}

/* Button Styling */
.submit-button,
.delete-button {
  padding: 12px 25px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.submit-button {
  background-color: #28a745;
  color: #fff;
  margin-top: 25px;
}

.submit-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.delete-button {
  background-color: #f44336;
  color: #fff;
}

.delete-button:hover {
  background-color: #d32f2f;
  transform: translateY(-2px);
}

/* Error and Success Message Styling */
.error, .success {
  width: 100%;
  max-width: 520px;
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
}

.error {
  background-color: #ffdddd;
  color: #d32f2f;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .expense-form-container {
    padding: 20px;
    margin: 20px;
  }

  .expense-form input,
  .expense-form select,
  .expense-form textarea {
    font-size: 15px;
    padding: 10px 15px;
  }

  .submit-button,
  .delete-button {
    font-size: 16px;
    padding: 10px 18px;
  }
}
