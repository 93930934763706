Coming Soon Page Styles
.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  /* Ensures header stays at the top */
  .coming-soon-container > header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  
  /* Centers the coming soon content, below the header */
  .coming-soon-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin-top: 100px; /* Adjust this based on your header height */
  }
  
  .coming-soon-content h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .coming-soon-content p {
    font-size: 24px;
    color: #777;
  }
  



/* Medium screens (tablets) */
@media (max-width: 768px) {
    .coming-soon-content h2 {
      font-size: 28px; /* Adjust font size for smaller screens */
    }
  
    .coming-soon-content p {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    .coming-soon-content {
      margin-top: 80px; /* Adjust space below header */
    }
  }
  
  /* Small screens (mobiles) */
  @media (max-width: 480px) {
    .coming-soon-content h2 {
      font-size: 24px; /* Smaller font for mobile */
    }
  
    .coming-soon-content p {
      font-size: 18px; /* Smaller font for mobile */
    }
  
    .coming-soon-container {
      padding: 20px; /* Add padding for small screens */
    }
  
    .coming-soon-content {
      margin-top: 70px; /* Reduce space below header for mobile */
    }
  }