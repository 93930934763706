/* src/Auth.css */

.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
  }
  
  .auth-card {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    padding: 12px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: #1e3a8a; /* Dark blue */
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #1e2a5a;
  }
  
  .remember-forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .social-login {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .apple, .google {
    padding: 12px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: black;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .google {
    background-color: white;
    color: #333;
    border: 1px solid #333;
  }
  
  /* Responsive design for mobile */
  @media (max-width: 768px) {
    .auth-card {
      width: 90%;
    }
  
    .social-login {
      flex-direction: column;
    }
  }
  