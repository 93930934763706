/* General Styles */
.properties-listing-container {
  padding: 30px;
  margin: 0 auto;
  max-width: 1200px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow around container */
  border-radius: 12px;
}

/* Title Styling */
.listing-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 25px;
  font-weight: 600;
  color: #333;
}

/* Add Property Button */
.add-property-button {
  display: inline-block;
  margin: 0 auto 25px;
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.add-property-button:hover {
  background-color: #0056b3;
  transform: translateY(-3px); /* Lift effect on hover */
}

/* Properties Grid */
.properties-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
}

/* Property Card */
.property-card-link {
  text-decoration: none;
  color: inherit;
}

.property-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1); /* Softer shadow */
  padding: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.property-card:hover {
  transform: translateY(-5px); /* Elevate on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.property-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

/* Property Card Text Styling */
.property-name {
  font-size: 22px;
  margin: 10px 0;
  color: #333;
  font-weight: 500;
}

.property-address {
  font-size: 16px;
  color: #555;
  margin: 5px 0;
}

.property-manager {
  font-size: 14px;
  color: #777;
  margin: 5px 0;
}

/* Action Buttons */
.edit-property-button,
.delete-property-button {
  margin: 10px 5px;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  font-size: 0.9em;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-property-button {
  background-color: #4CAF50;
}

.edit-property-button:hover {
  background-color: #388E3C;
}

.delete-property-button {
  background-color: #f44336;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.delete-property-button:hover {
  background-color: #d32f2f;
  transform: translateY(-2px); /* Slight lift on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .properties-listing-container {
      padding: 20px;
  }

  .property-card {
      padding: 15px;
  }

  .add-property-button {
      font-size: 16px;
  }
}

@media (max-width: 480px) {
  .listing-title {
      font-size: 24px;
  }

  .add-property-button {
      padding: 10px 20px;
  }

  .property-name {
      font-size: 20px;
  }

  .property-address, .property-manager {
      font-size: 14px;
  }
}
